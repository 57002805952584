import React, { useEffect, useRef } from 'react';
import './App.css';

const App: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    let stars: any[] = [];
    const baseSpeed = 0.05; // Base speed of stars
    const maxSpeedMultiplier = 2; // Maximum speed multiplier
    const starSpeed = 5; // Speed of stars for lightspeed effect
    let mouseX = canvas.width / 2;
    let mouseY = canvas.height / 2;
    let lastMouseX = mouseX;
    let lastMouseY = mouseY;
    let currentSpeedMultiplier = 1;
    let targetSpeedMultiplier = 1;
    let lastTime = performance.now();
    let isTabVisible = true;

    const calculateNumStars = () => {
      const starDensity = 0.0001; // Stars per pixel
      return Math.floor(canvas.width * canvas.height * starDensity);
    };

    const randomStar = () => {
      const colorProbability = Math.random();
      let color = '255, 255, 255'; // Default white color
      let flareMultiplier = 1; // Default flare size
      if (colorProbability < 0.1) {
        const colors = [
          '0, 191, 255',  // Deep Sky Blue
          '30, 144, 255', // Dodger Blue
          '70, 130, 180', // Steel Blue
          '135, 206, 250' // Light Sky Blue
        ];
        color = colors[Math.floor(Math.random() * colors.length)];
        flareMultiplier = 5; // Increase flare size for colored stars
      }
      return {
        x: Math.random() * canvas.width - canvas.width / 2,
        y: Math.random() * canvas.height - canvas.height / 2,
        z: Math.random() * canvas.width,
        o: Math.random() * 0.5 + 0.5, // Higher minimum opacity
        color: color,
        flareMultiplier: flareMultiplier,
        speed: baseSpeed,
      };
    };

    const resizeCanvas = () => {
      if (!canvas) return;
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      mouseX = canvas.width / 2;
      mouseY = canvas.height / 2;

      const numStars = calculateNumStars();
      stars = []; // Clear current stars
      for (let i = 0; i < numStars; i++) {
        stars.push(randomStar());
      }
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    const drawStar = (x: number, y: number, length: { x: number; y: number }, opacity: number, color: string) => {
      if (!ctx) return;
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(x + length.x, y + length.y);
      ctx.strokeStyle = `rgba(${color}, ${opacity})`;
      ctx.lineWidth = 2;
      ctx.stroke();
    };

    const draw = () => {
      if (!ctx || !canvas) return;
      ctx.fillStyle = 'black';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      for (let star of stars) {
        const perspective = canvas.width / (canvas.width + star.z);
        const x = (star.x * perspective) + canvas.width / 2;
        const y = (star.y * perspective) + canvas.height / 2;
        const length = {
          x: (star.x * perspective * starSpeed * currentSpeedMultiplier) / star.z,
          y: (star.y * perspective * starSpeed * currentSpeedMultiplier) / star.z,
        };
        const opacity = star.o * perspective;

        drawStar(x, y, length, opacity, star.color);
      }
    };

    const update = (deltaTime: number) => {
      if (!isTabVisible) return;

      // Smooth transition to target speed multiplier
      const speedAdjustmentRate = 0.02; // Adjust this rate to control the smoothness
      if (currentSpeedMultiplier < targetSpeedMultiplier) {
        currentSpeedMultiplier = Math.min(currentSpeedMultiplier + speedAdjustmentRate, targetSpeedMultiplier);
      } else if (currentSpeedMultiplier > targetSpeedMultiplier) {
        currentSpeedMultiplier = Math.max(currentSpeedMultiplier - speedAdjustmentRate, targetSpeedMultiplier);
      }

      for (let star of stars) {
        star.z -= starSpeed * (deltaTime / 16.67) * currentSpeedMultiplier; // Adjust speed based on frame time and current speed multiplier

        const x = (star.x * (canvas.width / (canvas.width + star.z))) + canvas.width / 2;
        const y = (star.y * (canvas.height / (canvas.height + star.z))) + canvas.height / 2;

        const dx = mouseX - x;
        const dy = mouseY - y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        const maxDistance = 150; // Maximum distance for mouse influence

        if (distance < maxDistance) {
          const influence = (maxDistance - distance) / maxDistance;
          star.x += dx * 0.01 * influence;
          star.y += dy * 0.01 * influence;
        }

        if (star.z <= 0 || x < 0 || x > canvas.width || y < 0 || y > canvas.height) {
          star.x = Math.random() * canvas.width - canvas.width / 2;
          star.y = Math.random() * canvas.height - canvas.height / 2;
          star.z = canvas.width;
          star.o = Math.random() * 0.5 + 0.5; // Higher minimum opacity
          const colorProbability = Math.random();
          if (colorProbability < 0.1) {
            const colors = [
              '0, 191, 255',  // Deep Sky Blue
              '30, 144, 255', // Dodger Blue
              '70, 130, 180', // Steel Blue
              '135, 206, 250' // Light Sky Blue
            ];
            star.color = colors[Math.floor(Math.random() * colors.length)];
            star.flareMultiplier = 5; // Increase flare size for colored stars
          } else {
            star.color = '255, 255, 255'; // Default white color
            star.flareMultiplier = 1; // Default flare size
          }
        }
      }
    };

    const animate = (time: number) => {
      const deltaTime = time - lastTime;
      lastTime = time;

      draw();
      update(deltaTime);

      requestAnimationFrame(animate);
    };

    const handleMouseMove = (event: MouseEvent) => {
      const dx = event.clientX - lastMouseX;
      const dy = event.clientY - lastMouseY;
      const distance = Math.sqrt(dx * dx + dy * dy);

      // Set target speed multiplier based on mouse movement
      targetSpeedMultiplier = 1 + Math.min(distance / 50, maxSpeedMultiplier - 1); // Normalize to the range [1, maxSpeedMultiplier]

      lastMouseX = event.clientX;
      lastMouseY = event.clientY;

      mouseX = event.clientX;
      mouseY = event.clientY;
    };

    const handleMouseStop = () => {
      targetSpeedMultiplier = 1; // Reset to base speed when mouse stops
    };

    const handleVisibilityChange = () => {
      isTabVisible = document.visibilityState === 'visible';
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseout', handleMouseStop);
    document.addEventListener('visibilitychange', handleVisibilityChange);
    requestAnimationFrame(animate);

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseout', handleMouseStop);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
      <div className="App">
        <canvas ref={canvasRef} />
        <div className="container">
          <img src="/android-chrome-512x512.png" alt="site logo" />
          <h1>STELLAR FLOW</h1>
          <h2>Streaming At Lightspeed Latency</h2>
        </div>
      </div>
  );
};

export default App;
